
<template>
  <div>
    <top-nav></top-nav>
    <div class="home">
      <div class="part1BookDemo">
        <iframe data-v-12f6728c="" id="player" frameborder="0" allowfullscreen="allowfullscreen" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" title="YouTube video player" src="https://www.youtube.com/embed/tySSgaYyeeU?&autoplay=1&mute=1&loop=1&showinfo=0&color=white&iv_load_policy=3&playlist=tySSgaYyeeU" class="video">
        </iframe>
        <div class="videoCover">
        </div>
        <div class="text">
            <div class="title">
                Off-the-plan VR Specialist
            </div>
            <div class="content">
                Bringing off-the-plan properties to life
            </div>
            <a href="https://dragonvr.au" target="_blank">
              <button class="part1Button">View Website</button>
            </a>
        </div>
      </div>
      <div class="part3PicsText">
        <div class="part3Middle">
          <div class="rightContent" >
            <div class="p1">
              <img src="./../../assets/imgs/pc/dragonVR/minicons1.png" class="pic" />
              <p>About DragonVR</p>
              <div class="content">
                  With a passionate team of virtual reality developers, interior designers, UI/UX artists and software engineers, DragonVR brings 2D floorplans to life through advanced 3D rendering and VR technologies.
              </div>
              <br>
              <div class="content">
                  DragonVR offers a fast turnaround time on projects and is cost effective, providing substantial savings in comparison to alternate display solutions. Virtual property tours allow developers, house and land builders and agents to showcase upcoming properties prior to construction, enhancing sales appeal.
              </div>
              <br>
              <div class="content">
                  With project marketing at the core of the products design, the DragonVR team seamlessly collaborates with vendors, creating state of the art VR experiences across all digital touch points and customises interiors to support the developer’s vision.
              </div>
            </div>
          </div>
          <div class="leftContent">
            <img src="./../../assets/imgs/pc/dragonVR/p1.png" class="pic2" />
            <div class="icons">
                <div class="iconText" v-for="i in icons" :key="'photoicons'+i">
                    <img :src="i.url" class="icon">
                    <div class="text">{{i.title}}</div>
                </div>
            </div>
          </div>
        </div>
        <div class="bottomContent">
          <div class="margin_space">
            <trusted/>
          </div>
          <!-- <div class="empty">
              <trusted></trusted>
          </div> -->
        </div>
        </br>
      </div>
      
      <div class="textFeatures">
          <div class="title" style="margin-left:20px">More <br>Features</div>
          <div class="cards">
              <div v-for="i in features" :key="'features'+i">
                <div class="cardB">
                  <div class="cardColor">
                    <div class="title">
                        <img :src="i.url" class="icon" />
                        <div class="subtitle">{{i.title}}</div>
                    </div>
                    <div class="content">
                        {{i.content}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="cardC">
                  <div class="cardCtitle">
                      Explore more about our VR products
                  </div>
                  <a href="https://dragonvr.au" target="_blank">

                    <button class="cardCbutton">View Website<img src="./../../assets/imgs/pc/footer/arrorRight.png" class="cardCicon"/></button>
                  </a>
              </div>
          </div>
      </div>
      <div class="part5">
        <div class="part5Comments">
          <other-product></other-product>
        </div>
      </div>
    </div>
    <foot-nav></foot-nav>
  </div>
</template>
<script>
import { LazyYoutube, LazyVimeo } from "vue-lazytube";
export default {
  data() {
      return{
          icons:[{url:require("./../../assets/imgs/pc/dragonVR/i1.png"),title:'Interior designer'},
          {url:require("./../../assets/imgs/pc/dragonVR/i2.png"),title:'VR developers'},
          {url:require("./../../assets/imgs/pc/dragonVR/i3.png"),title:'UI/UX artists'},
          {url:require("./../../assets/imgs/pc/dragonVR/i4.png"),title:'Software engineers'},],
          features:[{url:require("./../../assets/imgs/pc/dragonVR/mini1.png"),title:'From floorplans to virtual tours',content:'Change the way you present off-the-plan projects to potential buyers. In addition to floor plans, virtual walkthroughs allow buyers to visualise themselves living in the space.'},
          {url:require("./../../assets/imgs/pc/dragonVR/mini2.png"),title:'Customise your interiors',content:'Have a specific interior design vision which compliments your developments image? DragonVR can customise interiors to suit specific styles, eras and trends.'},
          {url:require("./../../assets/imgs/pc/dragonVR/mini3.png"),title:'Substantial saving on time and money',content:'In comparison to display suites, virtual reality renders can put developers ahead by months when it comes to project marketing sales, saving them hundreds of thousands of dollars.'},
          {url:require("./../../assets/imgs/pc/dragonVR/mini4.png"),title:'Gain VR inclusions in certain Arcanite subscription packages',content:'Arcanite offers free VR credits on its premium and enterprise packages to get your projects noticed for all the right reasons.'},
          {url:require("./../../assets/imgs/pc/dragonVR/mini5.png"),title:'Simple sharing via QR codes',content:'QR codes are a quick and easy way to share project rendering to clients. Scan the QR code to experience a virtual walkthrough created by the DragonVR team.'}],
        //   playerOptions: {
        //     playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
        //     autoplay: true, //如果true,浏览器准备好时开始回放。
        //     muted: false, // 默认情况下将会消除任何音频。
        //     loop: false, // 导致视频一结束就重新开始。
        //     preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        //     language: 'zh-CN',
        //     aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        //     fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        //     sources: [{
        //         type: "video/mp4",// 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
        //         src: "https://www.youtube.com/watch?v=tySSgaYyeeU" // url地址
        //     }],
        //     // poster: "https://p1.music.126.net/5zs7IvmLv7KahY3BFzUmrg==/109951163635241613.jpg?param=600y500", // 你的封面地址
        //     notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        //     controlBar: {
        //         timeDivider: true,
        //         durationDisplay: true,
        //         remainingTimeDisplay: false,
        //         fullscreenToggle: true  // 全屏按钮
        //     }
        // }
          
      }
  },
  created() {
  },
  mounted() {
  },
  watch: {
  },
  methods: {
  },
  components: {
    "top-nav": (resolve) => require(["./TopNav.vue"], resolve),
    "foot-nav": (resolve) => require(["./FooterNav.vue"], resolve),
    "other-product": (resolve) => require(["./OtherProduct.vue"], resolve),
    "trusted":(resolve) => require(["./Trusted.vue"], resolve),
    // "recognised":(resolve) => require(["./RecognisedDragonVR.vue"], resolve),
    // videoPlayer,        
    LazyYoutube,
    LazyVimeo,
  },
};
</script>
<style lang="scss" scoped>
.home{
  width: 100vw;
  display: inline-block;
  text-align: center;
  overflow-x:hidden;
  z-index: -99;
}

.video{
    position: absolute;
    z-index: -99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.videoCover{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
}
.part1BookDemo{
  width: 100vw;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  // display: relative;
//   background-color: blue;
  // z-index: -99;
// position: relative;
  z-index: 0;
    display: inline-block;
  align-content: center;
  text-align: center;
  vertical-align: center;
  .text{
      margin: auto;
      // width: 331px;
      height: 164px;
      z-index: 20;
      padding-top: 23%;
        // display: inline-block;
        align-content: center;
        text-align: center;
        vertical-align: center;
        position: relative;
        // top:400px;
        // left: 40%;
      .title{
        margin: auto;
        height: 42px;
        flex-grow: 0;
        font-family: Poppins-Bold;
        font-size: 36px;
        // font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
        display: inline-block;
        margin-bottom: 10px;
      }
      .content{
        margin: auto;
        margin-bottom: 30px;
        // margin-top: 5%;
        // width: 260px;
        height: 30px;
        flex-grow: 0;
        font-family: Poppins;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
      }
      a{
        display: inline-block;
        align-content: center;
        text-align: center;
        button{
          margin: auto;
          margin-top:5%;
          width: 141px;
          height: 42px;
          flex-grow: 0;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 10px;
          // padding: 10px 16px;
          border-radius: 5px;
          background-color: #1890ff;
          flex-grow: 0;
          font-family: Poppins-Bold;
          font-size: 16px;
          // font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.38;
          letter-spacing: normal;
          text-align: center;
          color: #fff;
        }
        button:hover{
          background-color: #096dd9;
        }
        button:active{
          background-color: #0757ae;
        }
      }
      
  }
}

.part3PicsText{
  display: flex;
  flex-direction: column;
  // justify-content: flex-start;
  align-items: flex-start;
  
  margin: auto;
  width:100vw;
  height: 1230px;
  position: relative;
  z-index: 0;
  top:-100px;
  background: #f7f9fb;
  .part3Middle{
    justify-content: space-between;
    margin: auto;
    margin-top: 30px;
    height: 900px;
    width: 1210px;
    display: flex;
    position: relative;
    .leftContent{
      position:relative;
      display: block;
      width: 721px;
      height: 597px;
      right:-20px;
      .pic2{
          width: 680px;
          height: 482px;
          object-fit: contain;
          position: absolute;
          top: 0;
          right: 0px;
      }
      .icons{
          width: 580px;
          height: 120px;
          display: flex;
          position: absolute;
          bottom: -100px;
          right: 50px;
          // flex-direction: row;
          // justify-content: flex-start;
          // align-items: flex-start;
          gap: 20px;
          margin: 51px 0 16px 81px;
          padding: 0;
          .iconText{
              width: 130px;
              height: 120px;
              flex-grow: 0;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
              gap: 10px;
              padding: 0;
              .icon{
                  width: 90px;
                  height: 90px;
                  flex-grow: 0;
                  object-fit: contain;
              }
              .text{
                  margin: 0;
                  height: 20px;
                  flex-grow: 0;
                  font-family: Poppins;
                  font-size: 14px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.43;
                  letter-spacing: normal;
                  text-align: center;
                  color: #314455;
				  white-space: nowrap;
              }
          }
      }
    }
    .rightContent{
      position:relative;
      display: inline-block;
      width: 454px;
      // margin-left: 75px;
      height: 597px;
      }
      .p1{
        margin: 10px;
        position:absolute;
        top: 70px;
        text-align: left;
        .pic1{
          width: 80px;
          height: 80px;
        }
        p{
          margin-left: 10px;
          position:relative;
          font-family: Poppins-Bold;
          font-size: 36px;
          // font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.17;
          letter-spacing: normal;
          text-align: left;
          color: #062440;
          margin-bottom: 20px;
        }
        .content{
          // word-break: break-all;

          margin:10px 0 10px 0;
          margin-left: 10px;
          width: 529px;
          flex-grow: 0;
          font-family: Poppins;
          font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: #314455;
        }
    }
  }
}

.part5{
  width: 100vw;
  background-color: #f7f9fb;
  height: 772px;
  padding-bottom: 20px;
  .part5Comments{
    // padding-top: 136px;
    margin: auto;
    width: 1210px;
    // height: 883px;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
}
.textFeatures{
  margin: auto;
  width: 1082px;
  height: 920px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 40px;
  padding: 0;
  margin-bottom:108px;
.title{
    flex-grow: 0;
    font-family: Poppins-Bold;
    font-size: 48px;
    // font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: left;
    color: #062440;
    // padding:10px;
}
.cards{
    width: 1400px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    // .cardB:hover{
    //     background-color: #eef7ff;
    //     }
    .cardB{
        width: 506px;
        height: 218px;
        flex-grow: 0;
        // display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
        padding: 20px;
        padding-right: 5px;
        padding-bottom: 5px;
        margin: 0 15px 25px 0;
        border-radius: 5px;
        .cardColor{
          width: auto;
          height: auto;
        }
        
    }
    .cardB:hover{
      background-color: #eef7ff;
      cursor: pointer;
    }
    .cardC{
        width: 526px;
        height: 196px;
        flex-grow: 0;
        // display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
        padding: 20px;
        border-radius: 5px;
        background-size: 526px 196px;
        background-repeat: no-repeat;
        background-image: url('./../../assets/imgs/pc/dragonVR/p2.png');
        .cardCtitle{
            width: 486px;
            height: 84px;
            flex-grow: 0;
            font-family: Poppins-Bold;
            font-size: 36px;
            // font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.17;
            letter-spacing: normal;
            text-align: left;
            color: #fff;
        }
        .cardCbutton{
            margin: 30px 0 0 303px;
            flex-grow: 0;
            font-family: Poppins-Bold;
            font-size: 16px;
            // font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: normal;
            text-align: center;
            color: #fff;
            width: 173px;
            height: 42px;
            flex-grow: 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 10px;
            padding: 10px 16px;
            border-radius: 5px;
            background-color: #062440;
			white-space: nowrap;
        }
        .cardCbutton:hover{
          background-color: #041729;
        }
        .cardCbutton:active{
          background-color: #020d17;
        }
        .cardCicon{
            width: 22px;
            height: 22px;
        }
    }
    .title{
        display: flex;
        .icon{
            width: 48px;
            height: 48px;
            flex-grow: 0;
            object-fit: contain;
            border-radius: 5px;
        }
        .subtitle{
            margin:10px;
            // height: 28px;
            flex-grow: 1;
            font-family: Poppins-SemiBold;
            font-size: 20px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.4;
            letter-spacing: normal;
            text-align: left;
            color: #062440;
        }
    }
        .content{
            padding:15px 10px 15px 10px;
            // height: 120px;
            flex-grow: 1;
            font-family: Poppins;
            font-size: 20px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: left;
            color: #062440;
        }
        .banner{
            width: 526px;
            height: 196px;
            flex-grow: 0;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: stretch;
            gap: 40px;
            margin: 0 0 0 40px;
            padding: 0;
            border-radius: 5px;
            .bannerText{
                width: 486px;
                height: 84px;
                flex-grow: 0;
                font-family: Poppins;
                font-size: 36px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.17;
                letter-spacing: normal;
                text-align: left;
                color: #fff;
            }
            .bannerButton{
                width: 173px;
                height: 42px;
                flex-grow: 0;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 10px;
                padding: 10px 16px;
                border-radius: 5px;
                background-color: #062440;
                .text{
                    width: 109px;
                    height: 22px;
                    flex-grow: 0;
                    font-family: Poppins;
                    font-size: 16px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.38;
                    letter-spacing: normal;
                    text-align: center;
                    color: #fff;
                }
            }
        }
    }
}

.pic{
    width: 91px;
    height: 91px;
}
.bottomContent{
    justify-content: flex-start;
    margin: auto;
    margin-top: 30px;
    width: 1210px;
    display: flex;
    position: relative;

    .margin_space{
      // margin-left: 20px;
      margin: 20px auto;
      margin-bottom: 100px;
    }
}

</style>